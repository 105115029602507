











import Vue from 'vue'
export default Vue.component('QLaader', {
    props: {
        isShow: {
            type: Boolean,
            required: true,
        }
    }
})
